<template>
  <div class="tree-tools__list">
    <r-button
      v-for="({ type, icon }, i) in toolKit(item)"
      :key="i"
      class="tree-tools__list-item"
      :icon="icon"
      :mini="miniTools"
      simple
      @click.native="e => handleClick(e, type)"
    />
    <r-button
      v-if="editMode"
      key="edit"
      class="tree-tools__list-item"
      :icon="renaming ? 'check' : 'edit'"
      :mini="miniTools"
      simple
      @click.native="e => handleRename(e, renaming ? 'save' : 'rename')"
    />
    <r-button
      v-if="editMode && renaming"
      key="cancel"
      class="tree-tools__list-item"
      icon="close-delete"
      :mini="miniTools"
      simple
      @click.native="e => handleRename(e, 'cancel')"
    />
  </div>
</template>

<script>
export default {
  props: {
    item: {
      type: Object,
      required: true
    },
    editMode: {
      type: Boolean,
      default: false
    },
    renaming: {
      type: Boolean,
      default: false
    },
    toolClick: {
      type: Function,
      default: () => null
    },
    toolKit: {
      type: Function,
      default: () => []
    },
    miniTools: {
      type: Boolean,
      default: false
    }
  },
  methods: {
    handleClick(e, type) {
      e.stopPropagation()
      e.preventDefault()
      const data = {
        children: this.item?.children || 0,
        datatype: this.item.datatype,
        geomType: this.item.geom_type,
        geom_type: this.item.geom_type,
        id: this.item.id,
        name: this.item.name,
        source_id: this.item.source_id
      }

      this.toolClick(type, data)
    },
    handleRename(e, cmd) {
      e.stopPropagation()
      e.preventDefault()

      this.$emit(cmd)
    }
  }
}
</script>
